import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

import Layout from "../components/layout"
import SEO from "../components/seo"

const RandoFr = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      rando: file(relativePath: { eq: "mascotteTourisme.png" }) {
        childImageSharp {
          fluid(maxWidth: 260) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pont: file(relativePath: { eq: "pont-pyle.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura: file(relativePath: { eq: "jura.png" }) {
        childImageSharp {
          fixed(width: 138) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      convivialite: file(relativePath: { eq: "convivialite.jpg" }) {
        childImageSharp {
          fixed(width: 259) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      champetre: file(relativePath: { eq: "champetre.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      vouglans: file(relativePath: { eq: "vouglans.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      famille: file(relativePath: { eq: "rando-famille.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      comte: file(relativePath: { eq: "comte.jpg" }) {
        childImageSharp {
          fixed(width: 260) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      clavelin: file(relativePath: { eq: "clavelin.jpg" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      tresor: file(relativePath: { eq: "tresor.jpg" }) {
        childImageSharp {
          fixed(width: 223) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      panneau: file(relativePath: { eq: "panneau.png" }) {
        childImageSharp {
          fixed(width: 152) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cirque: file(relativePath: { eq: "cirque.png" }) {
        childImageSharp {
          fixed(width: 260) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      bgexcursion: allFile (
          filter: {relativePath: {regex: "/bg-excursion/"}}
          sort: { fields: [relativePath] }
        ) {
        nodes {
          childImageSharp {
            fluid (maxHeight: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  return (
    <Layout location={location}>
      <SEO
        title="Accompagnateur de tourisme"
        description="Excursions, randonnées, terroir, géocaching"
      />
      <div className="submenu">
        <a href="#excursions">Excursions</a>
        <a href="#randonnee">Randonnées</a>
        <a href="#terroir">Terroir</a>
        <a href="#caching">Géocaching</a>
      </div>
      <div className="columns is-vcentered">
        <div className="column is-2">
          <Img
            fluid={data.rando.childImageSharp.fluid}
            alt="Tourisme dans le Jura"
            style={{ maxWidth: 260, width: "100%" }}
          />
        </div>
        <div className="column">
          <h2>Le Métier</h2>
          <p>
            C’est un nomade moderne. De sa passion des voyages, l’accompagnateur
            de tourisme en a fait son métier. Il conçoit programme d’excursion,
            visite et circuit touristique. Puis accompagne les touristes dans
            leur parcours en mettant à l’honneur culture, gastronomie et
            artisanat locaux.
          </p>
          <p>
            <b>Appelé aussi :</b> accompagnateur d’activités culturelles ou
            sportives, accompagnateur d’excursion ; accompagnateur patrimoine,
            accompagnateur tourisme ou écotourisme, animateur de voyages.
          </p>
        </div>
      </div>
      <div className="columns is-vcentered mt-6 is-variable is-7">
        <div className="column">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
            frameborder="0"
            src="https://www.youtube.com/embed/dN-2BSeO-0w"
            title="Mes randonnées jurasiennes"
            style={{ width: "100%", height: 255 }}
          ></iframe>
        </div>
        <div className="column">
          <Img
            fixed={data.panneau.childImageSharp.fixed}
            alt="Panneau"
            style={{ width: 152, float: 'right', marginTop: -90, marginLeft: 20 }}
            className="is-hidden-mobile"
          />
          <h2 id="excursions">Excursions</h2>
          <p>
            Excursions à la demi-journée ou à la journée afin de découvrir nos
            petites cités comtoises de caractère, l’histoire locale mais aussi
            les légendes, le terroir et également les artisans locaux et leur
            savoir faire si particulier (viticulteurs, coopérative fromagère…)
          </p>
          <p>Laissez vous guider sur les routes jurassiennes.</p>
        </div>
      </div>
      <div className="is-hidden-tablet-only is-hidden-mobile">
        <div className="slider" style={{margin: '2rem auto 6rem'}}>
          {data.bgexcursion.nodes.map(bg => (
            <BackgroundImage fluid={bg.childImageSharp.fluid} />
          ))}
        </div>
      </div>
      <h2 className="mt-6" id="randonnee">Randonnées</h2>
      <h3>"une heureuse découverte..."</h3>
      <div className="columns is-vcentered">
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.pont.childImageSharp.fixed}
            alt="Pont de la pyle"
            style={{ maxWidth: 200, width: "100%" }}
          />
        </div>
        <div className="column">
          <Img
            fixed={data.cirque.childImageSharp.fixed}
            alt="Cirque de Beaume"
            style={{ width: 260, float: 'right', marginTop: -125, marginLeft: 20 }}
            className="is-hidden-mobile"
          />
          <p>
            J’ai découvert la randonnée, il y a 3 ans et demi à l’occasion de
            sorties entre amis, alors, j’étais totalement novice en la matière.
            Heureusement pas besoin d’achat, j’ai retrouvé quasi intactes mes
            chaussures de marche, et mon petit sac à dos rouge Lafuma, très
            vintage, mais pratique.
          </p>
          <p>
            Cela peut sembler court comme expérience ! J’ai débuté tout d’abord
            comme simple participant mais j’ai tout de suite adhérer à cette
            activité. J’ai commencé progressivement par des petites balades de
            quelques kilomètres, et de faibles dénivelés. Au fur et à mesure,
            les distances se sont allongées mais également les dénivelés.
          </p>
        </div>
      </div>
      <p>
        Je pensais déjà bien connaître mon département natal, le Jura, par mon
        vécu et par mes expériences en Office de Tourisme, mais vraiment là,
        j’ai découvert un territoire nouveau.
      </p>
      <div className="columns is-vcentered">
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.jura.childImageSharp.fixed}
            alt="Jura"
            style={{ maxWidth: 138, width: "100%" }}
          />
        </div>
        <div className="column">
          <p>
            Le Jura se prête particulièrement bien à cette activité de part sa
            structure géologique et géographique. Il offre une grande variété de
            paysages de la plaine doloise et la Bresse, au Revermont, ainsi que
            le premier plateau et le haut jura. Voilà tout autant, de multiples
            terrains de jeux et de découvertes.
          </p>
          <p>
            J’ai très vite voulu organiser mes propres sorties car j’aime
            organiser et réunir les personnes.
          </p>
        </div>
      </div>
      <div
        className="is-uppercase mb-6"
        style={{ fontFamily: "made", color: "#de6c21", fontSize: "1.4em" }}
      >
        J’aime la randonnée pour plusieurs raisons :
      </div>
      <div className="columns is-vcentered">
        <div className="column">
          <h3>La convivialité</h3>
          <p>
            C’est un moment d’échanges privilégiés où chacun abandonne le temps
            d’un instant son statut social et professionnel pour redevenir un
            marcheur, un peu comme un retour au temps primitif, et un randonneur
            bien sûr.
          </p>
          <p>
            Dans un petit groupe, c’est très agréable d’échanger et d'évoluer à
            son rythme dans un environnement. La Nature favorise les
            discussions, et parfois les confidences. Pour les plus timides, il
            suffit de se mettre en retrait de quelques mètres ou un peu en
            avant. Les temps de pique nique ou de gouter sauront mettre tout le
            monde à l’aise autour de la dégustation de nos produits du terroir.
          </p>
        </div>
        <div className="column is-3 has-text-centered">
          <Img
            fixed={data.convivialite.childImageSharp.fixed}
            alt="Convivialité"
            style={{ maxWidth: 259, width: "100%" }}
          />
        </div>
      </div>
      <h3>Une activité physique</h3>
      <p>Cela permet de se dépenser, loin des performances sportives.</p>
      <p>
        Je vais privilégier les randonnées familiales pas difficiles, à facile
        pour qu’elles soient accessibles à un plus grand nombre
      </p>
      <div className="columns is-vcentered">
        <div className="column">
          <h3>La Nature</h3>
          <p>Communion avec la Nature</p>
          <p>
            Quelque soit la saison, la nature offre à nos yeux des paysages
            changeant, mais tout autant magnifiques.
          </p>
          <p>
            Chaque saison a sa couleur, le vert du printemps, le jaune des
            champs de blés l’été, le brun de l’automne, et le blanc de l’hiver.
            N’oublions par le bleu et ses multiples variations de nos lacs,
            rivières, cascades, et étangs.
          </p>
        </div>
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.champetre.childImageSharp.fixed}
            alt="Paysage champêtre"
            style={{ maxWidth: 200, width: "100%" }}
          />
        </div>
      </div>
      <div className="columns is-vcentered">
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.vouglans.childImageSharp.fixed}
            alt="Vouglans"
            style={{ maxWidth: 200, width: "100%" }}
          />
        </div>
        <div className="column">
          <p>
            Tout cela va ravir les amateurs de photos, les pauses et poses sont
            bien sûr comprises.
          </p>
          <p>
            Depuis, ce temps, j’ai attrapé un virus, mais celui-ci n’est pas
            très grave, c’est celui de la randonnée !
          </p>
          <p>
            Plusieurs dizaines de randonnées sont désormais à mon actif, et j’ai
            sélectionné pour vous les plus intéressantes, de part le paysage, le
            patrimoine bâti ou non, mais également l’histoire des lieux.
          </p>
          <p>
            Je vous invite donc à me suivre ainsi que mes bâtons de randonneur
            sur les chemins jurassiens.
          </p>
        </div>
      </div>
      <div style={{ fontSize: 20, color: "#000" }}>Je vous propose :</div>
      <div className="columns is-vcentered">
        <div className="column">
          <p>
            <strong>Randonnées familiales :</strong>
          </p>
          <p>
            accessibles à l’ensemble de la famille, classées très faciles ou
            faciles.
          </p>
          <p>
            De faibles dénivelés et courtes distances mais comportant un ou
            plusieurs intérêts touristiques (paysages, monuments historiques,
            belvédère). Je peux également proposer des randonnées plus longues
            sur l’ensemble d’une journée.
          </p>
          <p>
            Toutes sont sur des chemins balisés pour votre sécurité et testées
            et reconnues plusieurs fois avant votre venue.
          </p>
          <p>
            <strong>Randonnées gourmandes</strong> pour récompenser après
            l’effort dans un esprit de convivialité et de découverte des
            produits du terroir propices à la dégustation.
          </p>
        </div>
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.famille.childImageSharp.fixed}
            alt="Randonnée familiale"
            style={{ maxWidth: 200, width: "100%" }}
          />
        </div>
      </div>
      <h2 id="terroir">Terroir</h2>
      <div className="columns is-vcentered">
        <div className="column is-3 has-text-centered">
          <Img
            fixed={data.comte.childImageSharp.fixed}
            alt="Comté"
            style={{ maxWidth: 260, width: "100%" }}
          />
        </div>
        <div className="column">
          <p>
            Incontournables lors de vos escapades jurassiennes, les produits
            jurassiens sauront compléter vos papilles et attisés votre
            curiosité.
          </p>
          <p>Les fromages</p>
          <p>
            Impensable de ne pas goûter aux fromages jurassiens, avec dans
            l’ordre
          </p>
          <p>
            Le célèbre comté que l’on aime sans compter ! A vous de choisir son
            temps d’affinage (jeune, fruité, vieux) dans les nombreuses
            fruitières de notre région. AOP, le morbier, le bleu de gex,{" "}
          </p>
          <p>
            Mais aussi le Mont d’or, un incontournable de nos tables en hiver,
            au four et sur des pommes de terre, chaud , gratiné et coulant un
            vrai délice, le polinois… et bien d’autres
          </p>
        </div>
      </div>
      <p>
        Des visites guidées par des artisans fromagers locaux vous seront
        réservées. Ainsi, les fromages comtois n’auront plus de secret pour vous
        !
      </p>
      <h3>Les vins du Jura</h3>
      <div className="columns is-vcentered">
        <div className="column">
          <p>Un vignoble aux sept AOC !</p>
          <p>
            Les AOC « géographiques » : Arbois, Côtes du Jura, L’Etoile,
            Château-Chalon
          </p>
          <p>
            Les AOC « produits » : Macvin du Jura, un incontournable de votre
            apéritif jurassien, le Crémant du Jura, pour faire pétiller vos
            moments de fête, le Marc du Jura, un digestif très apprécié après un
            bon repas.
          </p>
          <p>Cinq Cépages, et mille saveurs</p>
          <p>
            Le Chardonnay, le Savagnin, le Poulsard, le Pinot noir, le
            Trousseau.
          </p>
          <p>
            Je vous ferai découvrir de petits producteurs qui sauront témoigner
            de la passion de leur métier ainsi que de l’attachement à la terre.
          </p>
          <p>
            Mais également, les salaisons avec les différentes saucisses,
            Montbéliard, Morteau, le miel et ses nombreux apiculteurs locaux
            conventionnels ou bio, et de nombreux brasseurs de bières.
          </p>
        </div>
        <div className="column is-3 has-text-centered">
          <Img
            fixed={data.clavelin.childImageSharp.fixed}
            alt="Clavelin"
            style={{ maxWidth: 250, width: "100%" }}
          />
        </div>
      </div>
      <h2 id="caching">Géocaching</h2>
      <div className="columns is-vcentered">
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.tresor.childImageSharp.fixed}
            alt="Géocaching"
            style={{ maxWidth: 223, width: "100%" }}
          />
        </div>
        <div className="column">
          <p>
            C’est une chasse au trésor moderne, vraiment destinée à tous. Très
            vite, on se prend au jeu de chercher, de fouiller, de scruter le
            moindre indice pour des découvrir des petits tubes contenants
            parfois des objets si leurs tailles le permettent.
          </p>
          <p>
            Ce sont également d’agréables promenades où le temps passe
            décidément trop vite. Partons ensemble à la chasse aux trésors ! Le
            Geocaching, les tubes de vos vacances et de vos loisirs !
          </p>
        </div>
      </div>
      <div style={{ fontFamily: "made", fontSize: 30 }}>
        <Link to="/fr/agenda/">☞ Mes prestations à venir</Link>
      </div>
    </Layout>
  )
}

export default RandoFr
